import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as ArrowDown } from '../../resources/img/arrowDownGreen.svg'
import '../../styles/_additionalPage.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const PrivacyPolicyRoute = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={`${CSS.ADDITIONAL_PAGE} ${CSS.BACKGROUND}`}>
			<Helmet>
				<title>Privacy Policy</title>
			</Helmet>
			<div className={CSS.WRAPPER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<div className={CSS.ADDITIONAL_BLOCK}>
					<h1 className={CSS.ADDITIONAL_TITLE}>PRIVACY POLICY</h1>
					<div className={CSS.TEXT}>
						<h2 className={CSS.MAIN_TEXT}>Introduction</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<p>
								Xtendr Zrt. (&quot;we&quot;, &quot;our&quot;, &quot;us&quot;) is committed to protecting
								and respecting your privacy. This Privacy Policy explains how we handle your personal
								information when you subscribe to our newsletter.
							</p>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Information We Collect</h2>
						<div className={CSS.CARD_SUBTITLE}>
							When you subscribe to our newsletter, you provide your email address directly to Sender, our
							newsletter service provider. Xtendr Zrt. does not store or process your email address
							directly. Sender acts as the data processor for this information, while Xtendr Zrt. remains
							the data controller. The legal basis for processing your email address is your consent.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Use of Your Information</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<p>
								Your email address is used exclusively for sending our newsletter and is not shared with
								any third parties. Xtendr Zrt. plans to send newsletters no more frequently than twice
								per month, with content including but not limited to new features, products, services,
								use cases and whitepapers, and data privacy-related news.
							</p>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Data Processing and Storage</h2>
						<div className={CSS.CARD_SUBTITLE}>
							All data processing and storage related to our newsletter subscription is handled by Sender.
							For detailed information about how Sender processes and protects your data, please refer to
							Sender&apos;s Privacy Policy at{' '}
							<a href="https://www.sender.net/privacy-policy/">https://www.sender.net/privacy-policy/</a>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Your Rights</h2>
						<div className={CSS.CARD_SUBTITLE}>
							You can unsubscribe from our newsletter at any time by clicking the unsubscribe link
							provided in every newsletter email. If you have any questions or concerns about your data,
							you can contact us at hello@xtendr.io, and we will coordinate with Sender to address your
							request.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Cookies and Tracking Technologies</h2>
						<div className={CSS.CARD_SUBTITLE}>
							Our website does not use cookies or any other tracking technologies.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Changes to This Privacy Policy</h2>
						<div className={CSS.CARD_SUBTITLE}>
							We may update this Privacy Policy from time to time. Any changes will be posted on this page
							with an updated revision date.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Contact Us</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<p>
								If you have any questions or concerns about this Privacy Policy or our data practices,
								please contact us at <a href="mailto:hello@xtendr.io">hello@xtendr.io</a>.
							</p>
							<br />
							<p>
								By subscribing to our newsletter, you acknowledge that you have read and understood this
								Privacy Policy and agree to the collection and use of your information as described
								herein.
							</p>
							<br />
							<br />
							<p>This Privacy Policy was last updated on December 6, 2024.</p>
						</div>
					</div>
				</div>
			</div>
			<div className={CSS.TITLE_ARROW}>
				<ArrowDown />
			</div>
		</div>
	)
}

export default PrivacyPolicyRoute
